import { FC, useEffect, useState } from 'react'
import { magic } from '@lib/magic'
import { UserContext } from '@lib/user-context'
import { MagicUserMetadata } from '@magic-sdk/types'

const MagicProvider: FC = ({ children }) => {
  const [user, setUser] = useState<
    MagicUserMetadata | null | { loading?: boolean }
  >({} as any)

  // If isLoggedIn is true, set the UserContext with user data
  // Otherwise, set UserContext to { user: null }
  useEffect(() => {
    setUser({ loading: true })

    magic.user.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) {
        magic.user.getMetadata().then((userData) => setUser(userData))
      } else {
        setUser(null)
      }
    })
  }, [])

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  )
}

export default MagicProvider
