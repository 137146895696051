export const IS_DEV = process.env.NODE_ENV !== 'production'
export const DOMAIN = IS_DEV
  ? process.env.IS_NGROK
    ? 'https://gate.maxr.dev'
    : 'http://localhost:3000'
  : 'https://coinmodal.com'

export const PLANS = new Map([
  ['monthly', 754184],
  ['yearly', 754185],
])

export const getPlanName = (plan?: number) => {
  if (!plan) return ''

  if (plan === PLANS.get('monthly')) {
    return 'Monthly'
  }

  if (plan === PLANS.get('yearly')) {
    return 'Yearly'
  }

  return ''
}
