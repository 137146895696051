import Router from 'next/router'

interface RedirectOptions {
  as?: string
  res?: any
  status?: number
}

export default function redirectTo(
  destination: string,
  { as, res, status }: RedirectOptions = {},
  replace = false,
) {
  if (!destination) {
    return
  }
  if (res) {
    res.statusCode = status || 302
    res.setHeader('location', as || destination)
    res.end()
    return
  } else {
    if (destination.match(/^https?:\/\//)) {
      // @ts-ignore stfu
      return (window.location = destination)
    }
    if (destination[0] === '/' && destination[1] !== '/') {
      if (replace) {
        return Router.replace(destination, as)
      } else {
        return Router.push(destination, as)
      }
    } else {
      // @ts-ignore stfu
      window.location = `${window.location.protocol}//${window.location.host}${destination}`
      return
    }
  }
}
