import { ToastContainerProps } from 'react-toast-notifications'

export default function NotificationArea({ children }: ToastContainerProps) {
  return (
    <div
      className="fixed bottom-0 sm:right-0 p-5 pt-0 w-full flex flex-col-reverse items-end notification-area"
      style={{ zIndex: 999 }}
    >
      {children}
    </div>
  )
}
