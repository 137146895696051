import cn from 'clsx'
import { ToastProps } from 'react-toast-notifications'

import styles from './notifications.module.css'

const ICONS = new Map([
  [
    'info',
    <svg
      className="h-6 w-6 text-blue-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>,
  ],
  [
    'error',
    <svg
      className="h-6 w-6 text-red-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>,
  ],
  [
    'success',
    <svg
      className="h-6 w-6 text-green-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>,
  ],
  [
    'warning',
    <svg
      className="h-6 w-6 text-orange-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
      />
    </svg>,
  ],
])

export default function Notification(props: ToastProps) {
  const { transitionState, onDismiss, appearance, children } = props

  const classNames = cn(
    'max-w-sm w-full bg-gray-900 text-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-5',
    'transform ease-out duration-300 transition-all',
    styles.notification,
    {
      'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2':
        transitionState === 'entering',
      'translate-y-0 opacity-100 sm:translate-x-0 ease-in duration-100':
        transitionState === 'entered',
      'opacity-0': transitionState === 'exiting',
    },
  )

  return (
    <div className={classNames}>
      <div className="p-4">
        <div className="flex items-start">
          <div className="flex-shrink-0">{ICONS.get(appearance)}</div>
          <div className="ml-3 w-0 flex-1 pt-0.5">{children}</div>
          <div className="ml-4 flex-shrink-0 flex">
            <button
              onClick={() => onDismiss()}
              className="bg-gray-900 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 focus:ring-offset-gray-900"
            >
              <span className="sr-only">Close</span>
              {/* <!-- Heroicon name: x --> */}
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
