export default function sentryFilter(error: any) {
  if (!error) {
    return false
  }

  try {
    const err = error.message || error.toString()
    const code = error.code || error.status || error.statusCode

    // These errors would be caught at build time. The reason why they occur in production
    // is because client side assets become unavailable after a deployment is issued.
    if (err.includes('Unexpected token')) return false

    if (err.includes('Payment') || err.includes('payment')) return false // Payment errors shouldn't be reported
    if (err.includes('ResizeObserver')) return false // Benign warning
    if (err.includes('PerformanceObserver')) return false // Benign warning
    if (err.includes('clipping info')) return false // Evernote extension
    if (/Deployment (.*) not found/g.test(err)) return false // Handled by 404

    // Google Translator Chrome Extension https://github.com/airbnb/lottie-web/issues/1134
    if (err.includes('a[b].target.className.indexOf is not a function'))
      return false
    if (
      code === 'rate_limited' ||
      code === 'too_many_requests' ||
      code === 429 ||
      code === 403 // Not authorized
    )
      return false // Rate limiting

    // This is caused by LastPass see here for more info:
    // https://forums.lastpass.com/viewtopic.php?f=6&t=349595
    if (
      err.includes('LP_explicit_ignored') ||
      err.includes('should_do_lastpass_here')
    ) {
      return false
    }
  } catch (e) {
    return true
  }

  return true
}
