// Packages
import fetch from 'isomorphic-unfetch'
import { parse as parseContentType } from 'content-type'
import redirectTo from '@lib/redirect-to'
import { magic } from '@lib/magic'
import { IS_DEV } from './constants'

export const PRODUCT_URL = process.env.NEXT_PUBLIC_PRODUCT_URL
  ? process.env.NEXT_PUBLIC_PRODUCT_URL
  : IS_DEV
  ? process.env.IS_NGROK
    ? 'https://gate.maxr.dev'
    : 'http://localhost:3000'
  : 'https://coinmodal.com'

const API_URL = `${PRODUCT_URL}/api`
const NETWORK_ERR_CODE = 'network_error'
const NETWORK_ERR_MESSAGE =
  'A network error has occurred. Check your connection and try again.'

const isServer = typeof window === 'undefined'
const agents = new Map()

class FetchError extends Error {
  public constructor(...args: any[]) {
    super(...args)
    Error.captureStackTrace(this, FetchError)
  }

  [key: string]: any
}

async function performFetch(path: string, opts: any = {}) {
  const headers = opts.headers || {}
  headers.authorization = `Bearer ${await magic.user.getIdToken()}`

  // accept path to be a full url or a relative path
  const url = path[0] === '/' ? API_URL + path : path
  let agent

  if (isServer) {
    const { parse } = require('url')
    const { protocol } = parse(url)
    if (protocol) {
      agent = getAgent(protocol)
    }
  }

  let res, data, err

  try {
    res = await fetch(url, { ...opts, headers, agent })

    if (opts.throwOnHTTPError && (res.status < 200 || res.status >= 300)) {
      const { type } = parseContentType(
        res.headers.get('Content-Type') || 'text/plain',
      )
      if (type === 'application/json') {
        data = await res.json()

        // Handle unauthenticated requests
        if (res.status === 401) {
          try {
            if (!opts.noLoginRedirect) return redirectTo('/login')
          } catch (e) {
            data.error = {
              code: 'not_authorized',
              message: 'Not authorized',
            }
          }
        }

        if (data.code && data.message) {
          data.error = data
        }

        err = new FetchError(
          !data.error
            ? `Unexpected Error (${opts.method} ${url})`
            : data.error.message || data.error.code,
        )

        err.res = res
        err.status = res.status
        err.code = data.error.code
      } else {
        // handle it below as network error
        let text = ''
        try {
          text = await res.text()
        } catch (berr) {
          console.error(berr)
        }
        const cerr = new FetchError(
          `Unexpected response content-type (${
            opts.method || 'GET'
          } ${url}): ` +
            type +
            `(${res.status}) ${text}`,
        )
        cerr.res = res
        cerr.status = res.status
        throw cerr
      }
    } else if (res.status === 401 && !opts.noLoginRedirect) {
      return redirectTo('/login')
    } else if (res.status === 402 && !opts.noLoginRedirect) {
      // TODO: Biling redirect used to be here
      // return redirectTo('/dashboard')
    } else if (res.status === 204) {
      // Since 204 means no content we return null
      data = null
    } else if ((res.headers.get('Content-Type') || '').startsWith('text/')) {
      data = await res.text()
    } else {
      data = await res.json()
    }
  } catch (e) {
    // Log the original error
    err = isServer ? e : new FetchError(NETWORK_ERR_MESSAGE)
    err.code = NETWORK_ERR_CODE
  }

  if (!err) return data
  if (err.status < 500) throw err
  err.stack = (err.stack ? err.stack : '') + ' ### Fetched URL: ' + url
  if (opts.body) {
    err.stack = (err.stack ? err.stack : '') + ' ### Request Body: ' + opts.body
  }
  throw err
}

const getAgent = (protocol: string) => {
  if (!agents.has(protocol)) {
    const http = require('http')
    const https = require('https')
    const module = protocol === 'https:' ? https : http
    const opts = {
      keepAlive: true,
      keepAliveMsecs: 10000,
      maxSockets: 100,
    }
    const agent = new module.Agent(opts)
    agents.set(protocol, agent)
  }

  return agents.get(protocol)
}

export default function fetchAPI(url: string, opts: any = {}) {
  // Set the accept header to JSON if not set
  opts.headers = opts.headers || {}
  opts.headers['Accept'] = opts.headers['Accept'] || 'application/json'
  opts.headers['Content-Type'] =
    opts.headers['Content-Type'] || 'application/json'

  if (isPlainObject(opts.body)) {
    opts.body = JSON.stringify(opts.body)
  }

  return performFetch(url, {
    throwOnHTTPError: true,
    ...opts,
  })
}

// roughly adapted from
// https://stackoverflow.com/a/5878101
function isPlainObject(obj: any) {
  if (typeof obj === 'object' && obj !== null) {
    const proto = Object.getPrototypeOf(obj)
    return proto === Object.prototype || proto === null
  }
  return false
}
