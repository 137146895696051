import * as Sentry from '@sentry/node'

import shouldReport from '@lib/sentry/filter'
import getUser from '@lib/get-user'
import { PHASE_PRODUCTION_BUILD } from 'next/constants'
import { IncomingMessage, IncomingHttpHeaders } from 'http'

const { NODE_ENV } = process.env
const { NEXT_PHASE } = process.env
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

if (
  NODE_ENV !== 'development' &&
  NODE_ENV !== 'test' &&
  NEXT_PHASE !== PHASE_PRODUCTION_BUILD
) {
  if (!SENTRY_DSN) {
    console.error(
      'Please define the `SENTRY_DSN` environment variable for the build step',
    )
  } else {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: `front@${process.env.NEXT_PUBLIC_VERSION}`,
      beforeSend(event: any) {
        // Do not report events on Preview Deployments
        if (SENTRY_ENVIRONMENT === 'preview') {
          return null
        }

        return event
      },
    })
  }
}

export async function reportToSentry(
  error: Error,
  {
    extras,
    req,
    statusCode,
  }: {
    extras?: any
    req?: IncomingMessage
    statusCode?: number
  } = {},
) {
  if (statusCode === 404) return
  if (!shouldReport(error)) return

  let user: any | null = null
  let headers: IncomingHttpHeaders | null = null

  try {
    user = (await getUser()).user
  } catch (error) {
    console.error(`Failed to fetch user information for error: ${error}`)
  }

  Sentry.withScope((scope) => {
    if (statusCode) {
      scope.setExtra('status_code', statusCode)
    }

    if (headers) {
      scope.setExtra('request_headers', headers)
    }

    if (user) {
      scope.setUser(user)
    }

    if (req && req.url) {
      scope.setExtra('request_url', req.url)
    }

    if (extras) {
      scope.setExtras(extras)
    }

    Sentry.captureException(error)
  })
}

export { Sentry }
