import '@styles/tailwind.css'
import '@styles/global.css'

import App from 'next/app'
import React from 'react'
import { ToastProvider } from 'react-toast-notifications'
import NotificationArea from '@components/notifications/notification-area'
import Notification from '@components/notifications/notification'

import { reportToSentry } from '@lib/sentry'
import MagicProvider from '@components/magic-provider'

export default class CMDashboard extends App {
  async componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    await reportToSentry(error, {
      extras: {
        componentStack: errorInfo.componentStack,
      },
    })

    // @ts-expect-error
    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps } = this.props
    // @ts-ignore Doesn't exist on components
    const getLayout = Component.getLayout || ((page) => page)

    return (
      <MagicProvider>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={4000}
          components={{
            Toast: Notification,
            ToastContainer: NotificationArea as any,
          }}
        >
          {getLayout(<Component {...pageProps} />, pageProps)}
        </ToastProvider>
      </MagicProvider>
    )
  }
}
