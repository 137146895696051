import { MagicUserMetadata } from '@magic-sdk/types'
import { createContext, Dispatch, SetStateAction } from 'react'

export const UserContext = createContext<
  [
    MagicUserMetadata | null | { loading?: boolean },
    Dispatch<
      SetStateAction<
        | MagicUserMetadata
        | {
            loading?: boolean | undefined
          }
        | null
      >
    >,
  ]
>([] as any)
